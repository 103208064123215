import React from 'react';
import { useNav } from '../customHooks/useNav';
import './Page.css';
import Card from './Card';

const Contact = () => {
	// useNav takes in a navLinkId and returns a ref
	// this ref is used to register the navLinkId that's
	// currently in view, and apply activeClass styling
	// to the corresponding nav childElement

	const contactRef = useNav('Contact');

	return (
		<section ref={contactRef} id='contactContainer'>

            <div>
                <h3>Road Map</h3>
        <p>Radical Robots is still in it's early stages, but we have much planned for the future.</p> <p>Most phases are still being worked on and we are always finding new ways to upgrade the ecosystem.</p> <p>Each phase will be unlocked only if the prior phase has been completed.</p> <p>We hope you join the Radical Robot revolution and see what is in store.</p>
        <a href="https://app.radicalrobots.xyz" target="_blank" rel="noreferrer">
            <button><span>Join </span></button>
        </a>
        </div>

            <div className="row">
                <div className="column">
			        <Card list={[<li key="1">Open January 8th</li>, <li key="2">800 RARO available</li>, <li key="3">Only available through Radical Robots App</li>]} phase={"Phase 1: Mint"} stat={"Complete"} prog={"comp"} />
                </div>
                <div className="column">
			        <Card phase={"Phase 4: Gen 2"} stat={"Locked"} prog={"locked"} list={[<li key="4">Spend 4 RARO to UPGRADE to Gen 2</li>, <li key="5">Only holders of gen 1 will be able to mint gen 2</li>, <li key="6">Upgrading will burn the 4 RARO used</li>]} />
                </div>
            </div>

            <div className="row">
                <div className="column">
			        <Card list={[<li key="7">February 8th, 2022</li>, <li key="8">View revealed RARO on OpenSea</li>, <li key="9">Minting to remain open until further notice</li>]} phase={"Phase 2: Unveil"} stat={"In Progress"} prog={"in-prog"} />
                </div>
                <div className="column">
			        <Card phase={"Phase 5: RR Battle"} stat={"Locked"} prog={"locked"} list={[<li key="10">Stake your RARO to earn ROBO</li>, <li key="11">Each Radical Robot will earn ROBO differently depending on stats</li>, <li key="12">Battle other RARO holders to gain new upgrades</li>]} />
                </div>
            </div>

            <div className="row">
                <div className="column">
			        <Card phase={"Phase 3: Air Drop"} stat={"Locked"} prog={"locked"} list={[<li key="12">Unlocks if 400 RARO are minted</li>, <li key="14">Gen 1 batch 2</li>, <li key="15">1 RARO distributed among all RARO hodlers</li>]} />
                </div>
                <div className="column">
			        <Card phase={"Phase 6: New Parts"} stat={"Locked"} prog={"locked"} list={[<li key="16">Earn new parts to gain more ROBO in the Radical Robots universe</li>, <li key="17">Parts and other boosts</li>, <li key="18">Upgrade UI for RR Battle</li>]} />
                </div>
            </div>

		</section>

	);
};

export default Contact;
