import React from 'react';
import { Home, About, Contact } from './';

const mystyle = {
      color: "#05ffa1",
      textDecoration: "none"
    };

const Main = () => (
	<main>
		<Home />
		<About />
		<Contact />
        <div>
            <footer>
                <p>Designed and created by <a style={mystyle} href="https://www.instagram.com/coswold8/" target="_blank" rel="noreferrer">@coswold8</a></p>
            </footer>
        </div>
	</main>
);

export default Main;
