import React from 'react';
import { useNav } from '../customHooks/useNav';
import './Page.css';
import hud1 from '../hud_2.png';

const About = () => {
	// useNav takes in a navLinkId and returns a ref
	// this ref is used to register the navLinkId that's
	// currently in view, and apply activeClass styling
	// to the corresponding nav childElement

	const aboutRef = useNav('About');

	return (
		<section ref={aboutRef} id='aboutContainer'>
			<img
                className="neg-marg"
				src={hud1}
				alt='hud1'
			/>
			<div className="text-pad">
				<h3>THE FACTORY</h3>
                <p>All parts for the Radical Robots collection have been created and analyzed. Our factory is up and running. All that's needed from you is the $MATIC to fuel the production. In return, you will recieve a unique Radical Robot of your own.</p>
                <ol>
                    <li>Go to the minting dapp and start the factory by supplying 2.4 $MATIC.</li>
                    <li>Our AI chooses parts from 4 different categories: Chassis, Mobility, Tool, and AI.</li>
                    <li>The schematics will be uploaded and a completely unique Radical Robot will be created.</li>
                    <li>Next, an environment is chosen and performance specs calculated.</li>
                    <li>Finally the Radical Robots will be revealed on February 8th, 2022.</li>
                </ol>
                <a href="https://app.radicalrobots.xyz" target="_blank" rel="noreferrer">
                    <button><span>Build Now </span></button>
                </a>
			</div>
		</section>
	);
};

export default About;
