import React from 'react';
import NavLink from './NavLink';
import { navLinks } from './navLinks';
import './Nav.css';
import logo from '../raro_logo.png';

const Nav = () => {
	return (
		<nav>
            <img src={logo} alt="Logo" />

			{navLinks.map(({ navLinkId, scrollToId }, idx) => (
				<NavLink key={idx} navLinkId={navLinkId} scrollToId={scrollToId} />
			))}
            <a href="https://app.radicalrobots.xyz" rel="noreferrer" target="_blank">
                <button><span>Open App </span></button>
            </a>
		</nav>
	);
};

export default Nav;
