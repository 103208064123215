import React from 'react';
import './Page.css';

function Card (props) {

	return (
		<div className="card">
            <div className="title">
				    <h4>{props.phase}</h4>
                </div>

            <div className="container">
                <div className="list">
				    <ul>
                        {props.list}
                    </ul>
			    </div>
            </div>
            <div className={props.prog}>
                <h5>{props.stat}</h5>
            </div>
		</div>
	);
};

export default Card;
