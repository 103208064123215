import React from 'react';
import { useNav } from '../customHooks/useNav';
import './Page.css';
import outer_ring from '../outer_ring.png';

const Home = () => {
	// useNav takes in a navLinkId and returns a ref
	// this ref is used to register the navLinkId that's
	// currently in view, and apply activeClass styling
	// to the corresponding nav childElement

	const homeRef = useNav('Home');

	return (
		<section ref={homeRef} id='homeContainer'>
			<img className="rotate or"
				src={outer_ring} alt="outer"
			/>
			<div className="neg-top">
				<h3>Radical Robots are Minting Now!</h3>
                <ul>
                    <li>Aka RARO tokens</li>
                    <li>1st Generation is 800 Unique NFT's</li>
                    <li>Minting now for 2.4 $MATIC</li>
                    <li>Unveiling February 8th, 2022</li>
                    <li>See the collection on <a href="https://opensea.io/collection/radicalrobots-1" target="_blank" rel="noreferrer">OpenSea</a></li>
                </ul>
                <a href="https://app.radicalrobots.xyz" target="_blank" rel="noreferrer">
                    <button><span>Buy $RARO </span></button>
                </a>
			</div>
		</section>
	);
};

export default Home;
